<template>
	<div class="page">
		<top />
		<div style="width: 100%;height: 55px;"></div>
		<designer />
		<div class="home">
			<div  @click="go('My')">我的主页</div>
			<div><</div>
			<div style="color: #E66617;">基本资料</div>
		</div>
		<div class="orderList">
			<div class="orderList-content">
				<div class="con1">
					<div class="left">头像</div>
					<img :src="imageUrl(head_pic)" alt="">
					<el-upload
					  class="avatar-uploader"
					  action="/api/newapi/Archives/articleImgUpload"
					  :show-file-list="false"
					  :on-success="handleAvatarSuccess1"
					>
					  <i id="upload" style="color: #E66617;margin-left: 12px;">修改头像</i>
					</el-upload>
					<!-- <div style="color: #E66617;margin-left: 12px;">修改头像</div> -->
				</div>
				<div class="con">
					<div class="left">昵称</div>
					<input type="text" placeholder="设计师" disabled="disabled" v-model="username">
				</div>
				<div class="con">
					<div class="left">邮箱</div>
					<input type="text" placeholder="请输入邮箱" v-model="email">
				</div>
				<div class="con">
					<div class="left">微信号</div>
					<input type="text" placeholder="请输入微信号" v-model="weixin">
				</div>
				<div class="con">
					<div class="left">用户名</div>
					<input type="text" placeholder="设计师" v-model="nickname">
				</div>
				
				<div class="con">
					<div class="left">QQ</div>
					<input type="text" placeholder="请输入QQ" v-model="qq">
				</div>
				<div class="con">
					<div class="left">公司名称</div>
					<input type="text" placeholder="请输入公司名称" v-model="address">
				</div>
				<div class="con">
					<div class="left">热门标签</div>
					<textarea placeholder="请输入热门标签" v-model="tags"></textarea>
				</div>
				<div class="save" @click="editUserInfoDo">保存</div>
			</div>
		</div>
		<info />
		<div style="width: 100%;height: 60px;background-color: #F6F6F6;"></div>
		<foot />
		</div>
</template>

<script>
	import top from "../../components/common/header.vue";
	import foot from "../../components/common/footer.vue";
	import info from "../../components/common/qrCode.vue";
	import designer from "../../components/common/designer.vue";
	import {
	  usersInfo,
	  editUserInfo
	} from "@/request/api.js";
	export default {
		components: {
			top,
			foot,
			designer,
			info
		},
		data() {
			return {
				// 基本信息 ----------------------------
				username: "",
				nickname: "",
				head_pic: "",
				email: "",
				qq: "",
				weixin: "",
				address: "",
				tags:""
			};
		},

		created() {
			 this.getUsersInfo();
		},
		methods: {
			//获取用户基本信息
			getUsersInfo() {
			  usersInfo().then((res) => {
			    console.log(res);
			    this.username = res.data.username;
			    this.nickname = res.data.nickname;
			    this.head_pic = res.data.head_pic;
			    this.email = res.data.email;
			    this.qq = res.data.qq;
			    this.weixin = res.data.weixin;
			    this.address = res.data.address;
			    this.phone = res.data.phone;
			    this.isBindingWx = res.data.isBindingWx;
				this.tags = res.data.tags;
			  });
			},
			go(url) {
				this.$router.push({
					name: url,
				});
			},
			handleAvatarSuccess1(res, file) {
			  this.head_pic = res.data.img;
			},
			//修改用户基本信息
			editUserInfoDo() {
			  editUserInfo({
			    username: this.username,
			    nickname: this.nickname,
			    head_pic: this.head_pic,
			    email: this.email,
			    qq: this.qq,
			    weixin: this.weixin,
			    address: this.address,
				tags:this.tags
			  }).then((res) => {
			    if (res.code == 200) {
			      this.$message({
			        message: res.msg,
			        type: "success",
			      });
			      this.$router.go(0);
			    } else {
			      this.$message.error(ress.msg);
			    }
			  });
			},
		},
	};
</script>

<style lang="less" scoped>
	.page {
		background: #F6F6F6;
		.home {
			padding: 0 20px;
			display: flex;
			align-items: center;
			font-size: 13px;
		
			div {
				margin-right: 7px;
			}
		}
		.orderList {
			margin-top: 15px;
			padding: 0 10px;
			.orderList-content {
				padding: 18px 12px;
				
				font-size: 13px;
				background-color: #FFFFFF;
				color: #000000;
				.con {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					padding-top: 20px;
					font-size: 12px;
					font-weight: 500;
					.left {
						text-align: right;
						width: 15%;
						color: #08243C;
					}
					input,textarea {
						width: 70%;
						padding: 14px 16px;
						outline: none;
						border: none;
						background-color: #F5F6F7;
					}
					img {
						width: 50px;
						height: 50px;
					}
				}
				.con1 {
					display: flex;
					flex-direction: row;
					align-items: center;
					font-size: 12px;
					font-weight: 500;
					.left {
						text-align: right;
						width: 15%;
						color: #08243C;
					}
					img {
						width: 50px;
						height: 50px;
						border-radius: 50%;
						margin-left: 6%;
					}
				}
				.save {
					border-radius: 5px;
					margin-top: 28px;
					background-color: #08243C;
					color: #FFFFFF;
					height: 42px;
					line-height: 42px;
					text-align: center;
				}
			}
		}

		
	}
</style>
